import React, { useState } from 'react';
import moment from "moment";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import Layout from "../components/Layout";
import { useBookings } from "../contexts/Bookings";
import locations from '../constants/locations';
import BookingDialog from "../components/BookingDialog";
import closestBookingToToday from "../utils/closestBookingToToday";

function Location(props) {
  const location = locations.getLocationBySlug(props.match.params.location);
  const { bookings } = useBookings();
  const [ bookingDialogId, setBookingDialogId ] = useState();
  const locationBookings = bookings.filter(booking => booking.location === location.id);

  const events = locationBookings.map((booking) => {
    return {
      start: booking.start.toDate(),
      // FullCalendar wants the moment immediately *after* the event ends
      end: booking.end.clone().add(1, 'days').toDate(),
      title: booking.user.name,
      id: booking.id,
      color: booking.status.colourCode
    }
  });

  const startMonth = props.match.params.month
    ? moment(props.match.params.month, "M-YYYY")
    : closestBookingToToday(bookings);

  return (
    <Layout title={location.name}>
      <FullCalendar
        defaultView="dayGridMonth"
        plugins={[ dayGridPlugin ]}
        firstDay={1}
        height={window.innerHeight - 120}
        fixedWeekCount={false}
        allDayDefault={true}
        events={events}
        eventClick={e => setBookingDialogId(e.event.id)}
        defaultDate={startMonth.toDate()}
      />
      <BookingDialog bookingId={bookingDialogId} handleClose={() => setBookingDialogId(false)} />
    </Layout>
  );
}

export default Location;