import React from 'react';
import moment from 'moment';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import { useBookings } from "../contexts/Bookings";
import locations from '../constants/locations';
import users from '../constants/users';
import BookingCard from "../components/BookingCard";

import styled from 'styled-components';
import Layout from "../components/Layout";
import {useAuth0} from "../contexts/Auth0";
import {Link as RouterLink} from "react-router-dom";
import {Link} from "@material-ui/core";

const LocationContainer = styled.div`
  padding-bottom: 24px;
`;

const Padding = styled.div`
  padding: 8px;
`;

function Dashboard() {
  const { bookings } = useBookings();
  const { user } = useAuth0();

  const filteredBookings = bookings
    .filter(booking => booking.end.clone().add(2, 'days').isAfter(moment()))
    .sort((a,b) => a.start.diff(b.start));

  const userBookings = filteredBookings
    .filter(booking => booking.user.id === user.family_member_id);

  const renderLocation = (location) => {
    const locationBookings = filteredBookings.filter(booking => booking.location === location.id);

    return (<LocationContainer key={location.id}>
      <Typography variant="h6" gutterBottom>{location.name}</Typography>
      {locationBookings.length === 0 && <Typography>No upcoming bookings</Typography>}

      <Grid container spacing={1}>
        {locationBookings.map(booking => (
            <BookingCard booking={booking} key={booking.id} />
          ))}
      </Grid>
    </LocationContainer>)
  };

  return (
    <Layout>
      <Typography paragraph><Link to="/rules" component={RouterLink}>Click here to view booking rules</Link></Typography>
      {userBookings.length ? (
        <>
          <Typography variant="h5" gutterBottom>Upcoming Bookings for {users.getUserById(user.family_member_id).name}</Typography>
          <Grid container spacing={1}>
            {userBookings.map(booking => (<BookingCard booking={booking} key={booking.id} type="user" />))}
          </Grid>
          <Padding/>
        </>
      ) : ''}

      <Typography variant="h5" gutterBottom>All Upcoming Bookings</Typography>

      {locations.data.map(renderLocation)}
    </Layout>
  );
}

export default Dashboard;