import React, { useState, useEffect, useContext } from "react";
import * as historyClient from "../utils/historyClient";

export const HistoryContext = React.createContext();
export const useHistory = () => useContext(HistoryContext);

export const HistoryProvider = ({
                                   children
                                 }) => {
  const [history, setHistory] = useState(false);

  useEffect (() => {
    const getHistory = async () => {
      const history = await historyClient.getHistory();
      setHistory(history);
    };

    getHistory();
  }, []);

  return (
    <HistoryContext.Provider value={{
      history,
    }}>
      {children}
    </HistoryContext.Provider>
  )
};