const Locations = {};

Locations.data = [
    {
        name: 'Piri - Top House',
        slug: 'piri-top',
        id: 0
    },{
        name: 'Millbrook - Chalmers Close',
        slug: 'millbrook-chalmers',
        id: 3
    }
];

Locations.slugs = {
    'piri-top': 0,
    'piri-bottom': 1,
    'palmers': 2,
    'millbrook-chalmers': 3,
    'millbrook-settlers': 4
};

Locations.getLocationById = function(id) {
    return Locations.data.find((location) => {
        return location.id === id;
    });
};

Locations.getLocationBySlug = function(slug) {
    return Locations.data.find((location) => {
        return location.slug === slug;
    });
};

export default Locations;