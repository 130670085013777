import React from 'react';
import styled from 'styled-components';

const Lozenge = styled.div`
  border-radius: 3px;
  display: inline-block;
  font-size: 11px;
  font-weight: 700;
  line-height: 99%;
  margin: 0;
  padding: 4px 5px 2px 5px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  color: #FFF;
  background: ${props => props.color};
  border: 1px solid ${props => props.color};
  max-height: 20px;
`;

export default Lozenge;