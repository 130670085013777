import Layout from "../components/Layout";
import React from "react";
import Typography from "@material-ui/core/Typography";

function Rules() {
  return (
    <Layout title="Booking Rules">
      <Typography paragraph>
<ol>
      <li>Confirming Booking Requests</li>
      <ul>
        <li>Bookings commencing between 1 March and 30 June will be confirmed at the end of November</li>
        <li>Bookings commencing 1 July and 31 October will be confirmed at the end of March</li>
        <li>Bookings commencing between 1 November and 28/29 February will be confirmed at the end of August.</li>
      </ul>
      <li>Non family bookings can only be made for off peak periods and will be confirmed 2 weeks prior to start date.</li>
      <ul>
        <li>Family are children and spouses, grandchildren and spouses, who are direct descendants of Sam and Alison.</li>
      </ul>
      <li>Peak Periods  are as follows:</li>
      <ul>
        <li>14th December to 13th February</li>
        <li>Easter to and including Autumn School Holidays.</li>
        <li>1st July  to end of Spring School Holidays.</li>
      </ul>
      <li>Cleaning  - Annalise Stanley</li>
      <ul>
        <li>After all stays Annalise is to carry out cleaning. Leave  CC2 tidy.</li>
        <li>Exception: if the next stay is to be an immediate family member, then the two parties can negotiate their own cleaning, 
        but Annalise must be notified early that she is not required to clean for that transition.</li>
      </ul>
      <li>When booking a non family for a stay, please put in comments which family member is making the booking.</li>
    </ol>
      
</Typography>
    </Layout>
  );
}

export default Rules;
