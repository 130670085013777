import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import locations from '../constants/locations';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import styled from 'styled-components';
import BookingForm from "../components/BookingForm";
import Layout from "../components/Layout";
import {useBookings} from "../contexts/Bookings";
import {useSnackbar} from "notistack";

function EditBooking({ match }) {
  const { updateBooking, bookings } = useBookings();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const booking = bookings.find(booking => booking.id === match.params.id);

  const formBooking = {
    start: booking.start,
    end: booking.end,
    location: booking.location,
    status: booking.status.id,
    user_id: booking.user.id,
    comment: booking.comment,
    id: booking.id
  };

  const [loading, setLoading] = useState(false);

  const onSubmit = async (booking) => {
    setLoading(true);
    await updateBooking(booking);
    enqueueSnackbar('Booking updated', { variant: 'success' });
    history.push(`/bookings/${locations.getLocationById(booking.location).slug}/${booking.start.format('M-YYYY')}`);
  };

  return (
    <Layout>
      <Typography variant="h5" gutterBottom>Edit Booking</Typography>
      <BookingForm onSubmit={onSubmit} loading={loading} initialBooking={formBooking} />
    </Layout>
  );
}

export default EditBooking;