import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HistoryIcon from '@material-ui/icons/History';
import AddIcon from '@material-ui/icons/Add';
import GavelIcon from '@material-ui/icons/Gavel';

import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { useAuth0 } from "../contexts/Auth0";
import locations from '../constants/locations';
import { Link, withRouter } from 'react-router-dom';

const drawerWidth = 260;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    [theme.breakpoints.down('sm')]: {
      padding: '24px 0'
    },
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    flexGrow: 1
  },
  nested: {
    paddingLeft: theme.spacing(4),
  }
}));

function Layout(props) {
  const { container, children, title } = props;
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const { logout } = useAuth0();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const ListItemLink = (props) => (
    <ListItem button {...props} component={Link} onClick={handleDrawerToggle} />
    );

  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
        <ListItemLink button key="home" to="/">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItemLink>
        <Divider />
        <ListItemLink button key="rules" to="/rules">
          <ListItemIcon>
            <GavelIcon />
          </ListItemIcon>
          <ListItemText primary="Rules" />
        </ListItemLink>
        <Divider />
        <ListItem key="locations">
          <ListItemIcon>
            <LocationOnIcon />
          </ListItemIcon>
          <ListItemText primary="Locations" />
        </ListItem>
        {locations.data.map((location) => (
          <ListItemLink button key={location.id} className={classes.nested} to={`/bookings/${location.slug}`}>
            <ListItemText primary={location.name} />
          </ListItemLink>
        ))}
        <Divider />
        <ListItemLink button key="history" to="/history">
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          <ListItemText primary="History" />
        </ListItemLink>
        <Divider />
        <ListItem button key="logout" onClick={logout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
        <Divider />
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            {title ? title : 'Morton Bookings'}
          </Typography>
          <Button variant="contained" color="secondary" component={Link} to="/bookings/create" size="small">{smallScreen ? <AddIcon /> : 'Create Booking'}</Button>
        </Toolbar>
      </AppBar>
      <nav aria-label="mailbox folders">
          <Drawer
            container={container}
            variant="temporary"
            open={drawerOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Container maxWidth="lg">
          {children}
        </Container>
      </main>
    </div>
  );
}

export default Layout;
