import React, { useEffect } from "react";
import styled from 'styled-components';
import { useAuth0 } from "./contexts/Auth0";
import { useBookings } from './contexts/Bookings';
import Dashboard from './pages/Dashboard';
import Location from './pages/Location';
import History from './pages/History';
import Rules from './pages/Rules';
import CircularProgress from '@material-ui/core/CircularProgress';
import './index.css';
import CssBaseline from "@material-ui/core/CssBaseline";
import { SnackbarProvider } from 'notistack';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import CreateBooking from "./pages/CreateBooking";
import {Typography} from "@material-ui/core";
import EditBooking from "./pages/EditBooking";

const Centre = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   width: 100vw;
   height: 100vh;
`;

function App() {
  const { loading, isAuthenticated, loginWithRedirect, user } = useAuth0();
  const { bookings } = useBookings();

  // Redirect to login page if user is unauthenticated
  useEffect(() => {
    if (loading || isAuthenticated) {
      return;
    }

    loginWithRedirect({ appState: { targetUrl: '/' } });
  }, [loading, isAuthenticated, loginWithRedirect]);

  if (loading || !isAuthenticated || !bookings) {
    return (
      <Centre><CircularProgress /></Centre>
    );
  }

  if (!user.family_member_id) {
    return (
      <Centre><Typography>Thanks for signing in! James will verify your account shortly. Please check again soon.</Typography></Centre>
    )
  }

  return (
    <Router>
      <SnackbarProvider maxSnack={3}>
        <CssBaseline />
        <Switch>
          <Route path="/" component={Dashboard} exact />
          <Route path="/history" component={History} exact />
          <Route path="/rules" component={Rules} exact />
          <Route path="/bookings/create" component={CreateBooking} exact />
          <Route path="/bookings/edit/:id" component={EditBooking} exact />
          <Route path="/bookings/:location/:month" component={Location} />
          <Route path="/bookings/:location" component={Location} />

        </Switch>
      </SnackbarProvider>
    </Router>
  );
}

export default App;