import API_HOST from "./apiHost";
import moment from "moment";
import users from "../constants/users";
import statuses from "../constants/statuses";

const DATE_FORMAT = 'D-M-YYYY';

export function fromApiFormat(booking) {
  return {
    start: moment(booking.start, DATE_FORMAT),
    end: moment(booking.end, DATE_FORMAT),
    user: users.getUserById(booking.user_id),
    status: statuses.getStatusById(booking.status),
    location: booking.location,
    id: booking._id,
    bookingId: booking.bookingId,
    comment: booking.comment
  };
}

function toApiFormat(booking) {
  return {
    start: booking.start.format(DATE_FORMAT),
    end: booking.end.format(DATE_FORMAT),
    location: booking.location,
    status: booking.status,
    user_id: booking.user_id,
    comment: booking.comment,
    bookingId: booking.bookingId
  };
}

export async function getBookings() {
  const bookingsRequest = await fetch(`${API_HOST}/api/bookings`);
  const bookings = await bookingsRequest.json();

  return bookings.map(fromApiFormat);
}

export async function createBooking(booking) {
  const request = await fetch(`${API_HOST}/api/bookings`, {
    method: 'POST',
    body: JSON.stringify(toApiFormat(booking)),
    headers: {
      'Content-Type': 'application/json',
      'X-MB-FamilyMemberId': window.mortonBookings.user.family_member_id
    }
  });
  const json = await request.json();

  return fromApiFormat(json);
}

export async function updateBooking(booking) {
  await fetch(`${API_HOST}/api/bookings/${booking.id}`, {
    method: 'PUT',
    body: JSON.stringify(toApiFormat(booking)),
    headers: {
      'Content-Type': 'application/json',
      'X-MB-FamilyMemberId': window.mortonBookings.user.family_member_id
    }
  });

  const getRequest = await fetch(`${API_HOST}/api/bookings/${booking.id}`);
  const json = await getRequest.json();

  return fromApiFormat(json);
}

export async function deleteBooking(booking) {
  await fetch(`${API_HOST}/api/bookings/${booking.id}`, {
    method: 'DELETE',
    headers: {
      'X-MB-FamilyMemberId': window.mortonBookings.user.family_member_id
    }
  });
}