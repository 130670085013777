import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import Layout from "../components/Layout";
import { useHistory, HistoryProvider } from "../contexts/History";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import styled from "styled-components";
import Lozenge from "../components/Lozenge";
import Statuses from "../constants/statuses";
import Locations from "../constants/locations";
import Users from "../constants/users";

const Wrapper = styled.div`
  padding: 8px;
`;

const lozengeColor = (type) => {
  switch (type) {
    case 'created':
      return '#388E3C';
    case 'updated':
      return '#4A6785';
    case 'deleted':
      return '#D32F2F';
  }
};

function Diff({before, after}) {
  const changes = [];

  if (before.start !== after.start) {
    changes.push(`Start date changed from ${before.start} to ${after.start}`);
  }

  if (before.end !== after.end) {
    changes.push(`End date changed from ${before.end} to ${after.end}`);
  }

  if (before.location !== after.location) {
    changes.push(`Location changed from ${Locations.getLocationById(before.location).name} to ${Locations.getLocationById(after.location).name}`);
  }

  if (before.status !== after.status) {
    const beforeStatus = Statuses.getStatusById(before.status);
    const afterStatus = Statuses.getStatusById(after.status);
    changes.push((<>Status changed from <Lozenge color={beforeStatus.colourCode}>{beforeStatus.name}</Lozenge> to <Lozenge color={afterStatus.colourCode}>{afterStatus.name}</Lozenge></>));
  }

  if (before.user_id !== after.user_id) {
    const beforeUser = Users.getUserById(before.user_id).name;
    const afterUser = Users.getUserById(after.user_id).name;

    changes.push(`Booking user changed from ${beforeUser} to ${afterUser}`);
  }

  if (before.comment !== after.comment) {
    changes.push(`Comment changed from "${before.comment}" to "${after.comment}"`);
  }

  return (
    <Grid item xs={12}>
      <ul>
      {changes.map(description => (<li key={description}>{description}</li>))}
      </ul>
    </Grid>
  )
}

function Created({ booking }) {
  const user = Users.getUserById(booking.user_id).name;
  const status = Statuses.getStatusById(booking.status);
  const location = Locations.getLocationById(booking.location).name;

  return (
    <Grid item xs={12}>
      <ul>
        <li><b>Booking for:</b> {user}</li>
        <li><b>Location:</b> {location}</li>
        <li><b>Arrival date:</b> {booking.start}</li>
        <li><b>Departure date:</b> {booking.end}</li>
        {booking.comment && <li><b>Comment:</b> {booking.comment}</li>}
        <li><b>Status:</b> {status.name}</li>
      </ul>
    </Grid>
  )
}

function HistoryCard(props) {
  const { entry } = props;
  return (
    <Grid item xs={12} key={entry._id}>
      <Paper>
        <Wrapper>
          <Grid container>
            <Grid item sm={3} xs={6}><b>{entry.timestamp.format('D MMMM')}</b></Grid>
            <Grid item sm={3} xs={6}><b>{entry.user.name}</b></Grid>
            <Grid item sm={3} xs={6}><b>Booking #{entry.bookingId}</b></Grid>
            <Grid item sm={3} xs={6}><b><Lozenge color={lozengeColor(entry.action)}>{entry.action}</Lozenge></b></Grid>
            {entry.action === 'updated' && (<Diff before={entry.before} after={entry.after} />)}
            {entry.action === 'created' && <Created booking={entry.after} />}
          </Grid>
        </Wrapper>
      </Paper>
    </Grid>
  )
}

function HistoryWithContext(props) {
  return (
    <Layout>
      <HistoryProvider>
        <History />
      </HistoryProvider>
    </Layout>
  );
}

function History(props) {
  const { history } = useHistory();

  if (!history) {
    return (<CircularProgress />);
  }

  console.log(history);

  return (
    <div>
      <Grid container spacing={1}>
        {history.map(historyEntry => <HistoryCard entry={historyEntry} key={historyEntry._id}/>)}
      </Grid>
    </div>
  );
}

export default HistoryWithContext;