const Users = {};

Users.users = [{"name": "Alex Griffiths", "id": 16}, {
  "name": "Alison Morton",
  "id": 19
}, {"name": "Ben McArthur", "id": 10}, {
  "name": "Biddy Winstone",
  "id": 3
}, {"name": "Bridget Winstone-Kight", "id": 25}, {
  "name": "Chris Morton",
  "id": 20
}, {"name": "Claus Brewer", "id": 21}, {
  "name": "Colin McArthur",
  "id": 28
}, {"name": "David McArthur", "id": 11}, {
  "name": "Doe Winstone",
  "id": 27
}, {"name": "Ellie Griffiths", "id": 13}, {
  "name": "Emily Brewer",
  "id": 4
}, {"name": "Fliss Winstone", "id": 6}, {
  "name": "James McArthur",
  "id": 9
}, {"name": "Jeremy Kotua", "id": 22}, {"name": "Jo Morton", "id": 29}, {
  "name": "John Griffiths",
  "id": 30
}, {"name": "Johnny Griffiths", "id": 17}, {
  "name": "Julia Morton",
  "id": 26
}, {"name": "Juliet McArthur", "id": 8}, {
  "name": "Kate Morton",
  "id": 1
}, {"name": "Madison McArthur", "id": 23}, {
  "name": "Nick Griffiths",
  "id": 15
}, {"name": "Non-family", "id": 18}, {"name": "Paul Sullivan", "id": 24}, {
  "name": "Pete Griffiths",
  "id": 14
}, {"name": "Rosie Griffiths", "id": 12}, {
  "name": "Sam Winstone-Kight",
  "id": 7
}, {"name": "Steph Kotua", "id": 5}, {"name": "Tom Morton", "id": 2}];

Users.getUserById = (id) => {
  return Users.users.find((user) => {
    return user.id === id;
  });
};

export default Users;