let Statuses = {};

Statuses.status = [
    {
        name: 'Requested',
        id: 1,
        colour: "blue",
        colourCode: "#4A6785"
    },
    {
        name: 'Confirmed',
        id: 2,
        colour: "green",
        colourCode: "#388E3C"
    },
    {
        name: 'Denied',
        id: 3,
        colour: "red",
        colourCode: "#D32F2F"
    }
];

Statuses.getStatusById = (id) => {
    return Statuses.status.find((status) => {
        return status.id === id;
    });
};

module.exports = Statuses;