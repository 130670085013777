import moment from "moment";
import API_HOST from "./apiHost";
import users from "../constants/users";

export async function getHistory() {
  const historyRequest = await fetch(`${API_HOST}/api/history?sort=-timestamp`);
  const history = await historyRequest.json();

  return history.map(fromApiFormat);
}

function fromApiFormat(history) {
  return {
    ...history,
    user: users.getUserById(history.user_id),
    timestamp: moment(history.timestamp)
  }
}



