import React, { useState } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker } from 'react-dates';
import { HORIZONTAL_ORIENTATION, VERTICAL_ORIENTATION } from 'react-dates/constants'
import styled from 'styled-components';
import { useAuth0 } from "../contexts/Auth0";
import users from '../constants/users';

import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import SaveIcon from "@material-ui/icons/Save";

import locations from '../constants/locations';
import statuses from '../constants/statuses';
import validateBooking from "../utils/validateBooking";
import {CircularProgress, useTheme} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

const FormContainer = styled.div`
  max-width: 600px;
`;

const DatePickerContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
`;

const ButtonWrapper = styled.div`
  padding-top: 8px;
  
  button {
    margin-right: 16px;
  }
`;

const INITIAL_BOOKING = {
  user_id: null,
  location: null,
  comment: '',
  status: 1,
};

function BookingForm({ initialBooking = INITIAL_BOOKING, onSubmit, loading }) {
  const { user } = useAuth0();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const orientation = smallScreen ? VERTICAL_ORIENTATION : HORIZONTAL_ORIENTATION;

  const [booking, setBooking] = useState(initialBooking);
  const [focusedInput, setFocusedInput] = useState();
  const valid = validateBooking(booking);

  const availableUsers = users.users.filter(familyMember =>
    user.is_admin || familyMember.id === user.family_member_id || familyMember.id === 18
  );

  return (
    <FormContainer>
      <TextField
        value={booking.user_id}
        select
        label="Booking for"
        fullWidth
        margin="normal"
        onChange={(e) => setBooking({ ...booking, user_id: e.target.value})}
      >
        {availableUsers.map(user => <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>)}
      </TextField>

      <TextField value={booking.location} select label="Location" fullWidth margin="normal"
                 onChange={(e) => setBooking({ ...booking, location: e.target.value})}
      >
        {locations.data.map(location => <MenuItem value={location.id} key={location.id}>{location.name}</MenuItem>)}
      </TextField>

      <DatePickerContainer>
      <DateRangePicker
        startDate={booking.start}
        startDateId="START_DATE"
        endDate={booking.end}
        endDateId="END_DATE"
        onDatesChange={
          ({ startDate, endDate }) => setBooking({
            ...booking,
            start: startDate,
            end: endDate
          })}
        focusedInput={focusedInput}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        startDatePlaceholderText="Arrive"
        endDatePlaceholderText="Depart"
        firstDayOfWeek={1}
        displayFormat="D/M/YYYY"
        orientation={orientation}
        withFullScreenPortal={smallScreen}
        />
      </DatePickerContainer>

      <TextField
        label="Comment"
        margin="normal"
        multiline
        fullWidth
        onChange={(e) => setBooking({ ...booking, comment: e.target.value })}
        value={booking.comment}
      />

      {user.is_admin && (
        <TextField value={booking.status} select label="Status" fullWidth margin="normal"
                   onChange={(e) => setBooking({ ...booking, status: e.target.value})}
        >
          {statuses.status.map(status => <MenuItem key={status.id} value={status.id}>{status.name}</MenuItem>)}
        </TextField>
      )}

      <ButtonWrapper>
        <Button
          variant="contained"
          color="default"
          size="medium"
          onClick={() => window.history.back()}
        >
          Cancel
        </Button>

      <Button
        variant="contained"
        color="primary"
        size="medium"
        startIcon={<SaveIcon />}
        disabled={valid !== true}
        onClick={() => onSubmit(booking)}
      >
        Save
      </Button>
        {loading && <CircularProgress size={24} />}
      </ButtonWrapper>
    </FormContainer>
  );
}

export default BookingForm;