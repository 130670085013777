import React from 'react';
import { useHistory } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import {useBookings} from "../contexts/Bookings";
import {useAuth0} from "../contexts/Auth0";
import {useSnackbar} from "notistack";

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

/**
 * @return {boolean}
 */

export default function CustomizedDialogs({ bookingId, handleClose }) {
  const { bookings, deleteBooking } = useBookings();
  const theme = useTheme();
  const history = useHistory();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const { user } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();

  if (!bookingId) {
    return false;
  }

  const booking = bookings.find(booking => booking.id === bookingId);
  const open = Boolean(booking);

  const canEdit = user.is_admin ||
    (booking.user.id === user.family_member_id) ||
    (booking.user.id === 18);

  const handleEdit = () => {
    history.push(`/bookings/edit/${booking.id}`);
    handleClose();
  };

  const handleDelete = async () => {
    handleClose();
    await deleteBooking(booking);
    enqueueSnackbar('Booking deleted', { variant: 'success' });
  };

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth fullScreen={fullScreen}>
        <DialogTitle onClose={handleClose}>
          Booking for {booking.user.name}
        </DialogTitle>
        <DialogContent dividers>
          <Typography><b>Arrival date:</b> {booking.start.format('dddd, Do MMMM YYYY')}</Typography>
          <Typography><b>Departure date:</b> {booking.end.format('dddd, Do MMMM YYYY')}</Typography>
          {booking.comment && <Typography><b>Comment:</b> {booking.comment}</Typography>}
          <Typography><b>Status:</b> {booking.status.name}</Typography>
          <Typography><b>ID:</b> {booking.bookingId}</Typography>
        </DialogContent>
        <DialogActions>
          {canEdit && <><Button autoFocus onClick={handleDelete} color="primary">
            Delete
          </Button>
          <Button autoFocus onClick={handleEdit} color="primary">
            Edit
          </Button></>}
        </DialogActions>
      </Dialog>
    </div>
  );
}
