import React, { useState, useEffect, useContext } from "react";
import * as bookingsClient from "../utils/bookingsClient";

export const BookingsContext = React.createContext();
export const useBookings = () => useContext(BookingsContext);

export const BookingsProvider = ({
  children
}) => {
  const [bookings, setBookings] = useState(false);

  useEffect (() => {
    const getBookings = async () => {
      const bookings = await bookingsClient.getBookings();
      setBookings(bookings);
    };

    getBookings();
  }, []);

  const createBooking = async (booking) => {
    const newBooking = await bookingsClient.createBooking(booking);
    setBookings(bookings.concat([newBooking]));
  };

  const updateBooking = async (booking) => {
    const newBooking = await bookingsClient.updateBooking(booking);
    setBookings(bookings.filter(booking => booking.id !== newBooking.id).concat([newBooking]));
  };

  const deleteBooking = async (booking) => {
    await bookingsClient.deleteBooking(booking);
    setBookings(bookings.filter(a => a.id !== booking.id));
  };

  return (
    <BookingsContext.Provider value={{
      bookings,
      createBooking,
      updateBooking,
      deleteBooking
    }}>
      {children}
    </BookingsContext.Provider>
  )
};