import moment from 'moment';

export default function validateBooking(booking) {
  // Start date present
  if (!booking.start) {
    return "Please select a start date";
  }

  // End date present
  if (!booking.end) {
    return "Please select an end date";
  }

  // Booking length less than 40 days
  if (booking.end.isAfter(moment(booking.start).add(40, 'days'))) {
    return "A booking cannot be longer than 40 days";
  }

  // user_id present
  if (!booking.user_id) {
    return "Please select whose name the booking should be under";
  }

  // Comment length
  if (booking.comment.length > 500) {
    return "Comments cannot be longer than 500 characters";
  }

  if (booking.location === null) {
    return "Please select a location";
  }

  return true;
}