import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import formatDateRange from "../utils/formatDateRange";

import styled from 'styled-components';
import React, {useState} from "react";
import locations from '../constants/locations'
import Lozenge from "./Lozenge";
import BookingDialog from "../components/BookingDialog";

const Wrapper = styled.div`
  padding: 8px;
  cursor: pointer;
`;

const LozengeWrapper = styled.div`
  @media (min-width: 600px) {
    text-align: right;
  }
`;

function BookingCard({ booking, type }) {
  const [showBookingDialog, setShowBookingDialog] = useState(false);

  return (
    <Grid item xs={12}>
      {showBookingDialog && <BookingDialog bookingId={booking.id} handleClose={() => setShowBookingDialog(false)} />}
      <Paper>
        <Wrapper onClick={() => setShowBookingDialog(true)}>
          <Grid container>
            <Grid item xs={6} sm={4}>
              <Typography>{formatDateRange(booking)}</Typography>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Typography>{type === 'user' ? locations.getLocationById(booking.location).name : booking.user.name}</Typography>
            </Grid>
            <Grid item xs={2} sm={2}>
              <LozengeWrapper>
                <Lozenge color={booking.status.colourCode}>{booking.status.name}</Lozenge>
              </LozengeWrapper>
            </Grid>

            {booking.comment && (
              <>
                <Grid item sm={4} />
                <Grid item xs={12} sm={8}>
                  <Typography variant="body2" color="textSecondary">{booking.comment}</Typography>
                </Grid>
              </>
            )}

          </Grid>
        </Wrapper>
      </Paper>
    </Grid>
  )
}

export default BookingCard;